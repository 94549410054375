import React from "react";

import MapMarker from "@pagerland/icons/src/line/MapMarker";
import textToMultiline from "@pagerland/common/src/utils/textToMultiline";
import Skype from "@pagerland/icons/src/line/Skype";
import Envelope from "@pagerland/icons/src/line/Envelope";
import FacebookF from "@pagerland/icons/src/line/FacebookF";
import Instagram from "@pagerland/icons/src/line/Instagram";
import Twitter from "@pagerland/icons/src/line/Twitter";
import Linkedin from "@pagerland/icons/src/line/Linkedin";
import Cog from "@pagerland/icons/src/line/Cog";
import Figma from "@pagerland/icons/src/line/Figma";
import LifeRing from "@pagerland/icons/src/line/LifeRing";

import Typography from "@pagerland/common/src/components/Typography";
import Img from "@pagerland/common/src/components/Img";

// import { smoothLinkProps } from "@pagerland/common/src/utils";

import maschable from "../assets/brands/maschable.svg";
import extremetech from "../assets/brands/extremetech.svg";
import techbragins from "../assets/brands/techbragins.svg";
import envato from "../assets/brands/envato.svg";
import geek from "../assets/brands/geek.svg";

import features1 from "../assets/features.svg";
import features2 from "../assets/features-2.svg";
import features3 from "../assets/features-3.svg";
import features4 from "../assets/features-4.svg";

import Screenshot from "../assets/screenshot.jpg";
import Screenshot2x from "../assets/screenshot@2x.jpg";
import Screenshot2 from "../assets/screenshot2.jpg";
import Screenshot22x from "../assets/screenshot2@2x.jpg";
import Screenshot3 from "../assets/screenshot3.jpg";
import Screenshot32x from "../assets/screenshot3@2x.jpg";
import Screenshot4 from "../assets/screenshot4.jpg";
import Screenshot42x from "../assets/screenshot4@2x.jpg";
import Screenshot5 from "../assets/screenshot5.jpg";
import Screenshot52x from "../assets/screenshot5@2x.jpg";
import Avatar from "../assets/avatar.jpg";
import Avatar2x from "../assets/avatar@2x.jpg";

export default {
  title: "Gorila Digital",
  navbar: {
    links: [
      {
        to: "",
        label: "Home",
      },
      {
        to: "about",
        label: "About",
      },
      {
        to: "features",
        label: "Features",
      },
      {
        to: "screens",
        label: "Screens",
      },
      // {
      //   to: "clients",
      //   label: "Clients",
      // },
      {
        to: "contact",
        label: "Contact",
      },
    ],
    actions: [
      {
        href: "/es/",
        label: "Español",
      },
    ],
  },
  welcome: {
    title: [
      "Gorila Digital. ",
      <Typography as="span" variant="h1" color="primary">
        Your
      </Typography>,
      " new Software Advisers!",
    ],
    text:
      "Here in Gorila Digital, we are passionate about software and we want you to feel the same. Relax and let us work on your idea.",
    actions: [],
  },
  brands: [
    {
      src: maschable,
      alt: "Maschable",
      LinkProps: {
        href: "https://mypags.app",
        title: "Maschable",
      },
    },
    {
      src: extremetech,
      alt: "Extremetech",
      LinkProps: {
        href: "https://mypags.app",
        title: "Extremetech",
      },
    },
    {
      src: techbragins,
      alt: "Techbragins",
      LinkProps: {
        href: "https://mypags.app",
        title: "Techbragins",
      },
    },
    {
      src: envato,
      alt: "Envato",
      LinkProps: {
        href: "https://mypags.app",
        title: "Envato",
      },
    },
    {
      src: geek,
      alt: "Geek",
      LinkProps: {
        href: "https://mypags.app",
        title: "Geek",
      },
    },
  ],
  about: {
    sections: [
      {
        IconWrapperProps: {
          bg: "primary",
        },
        title: "Planning and estimation",
        text:
          "Let us know what are your needs, we can provide feedback and shape your idea to achieve maximum benefit. We will provide finally a time and cost estimations.",
        icon: Cog,
      },
      {
        IconWrapperProps: {
          bg: "secondary",
        },
        title: "Design and Development",
        text:
          "We have the best designers to create a clean UI, focused on delivering the best User Experience. Our high skilled developers will use best practices to create long-lasting software.",
        icon: Figma,
      },
      {
        IconWrapperProps: {
          bg: "tertiary",
        },
        title: "Incredible support",
        text:
          "You can count on us. After the product is delivered our mission is to provide the best support to our clients. We won't let you down.",
        icon: LifeRing,
      },
    ],
  },
  features: {
    title: "What can we do for you?",
    text:
      "We are specialized in many different technologies. That will help in finding the best solution for your problems or deliver your idea most productively.",
    features: [
      {
        title: "Web Development",
        text:
          "Websites from e-commerce to web apps, dashboards, admin panels, data visualization, PWA we are here to help you to decide what fits better for your business and turn it to reality.",
        details: <Img src={features1} width="100%" maxHeight={200} />,
      },
      {
        title: "Mobile Development",
        text:
          "If your idea accommodates better for having your mobile APP, we can deliver fast-paced and cost adjusted mobile apps. Using the best technologies for speed up development for both main mobile platforms: iOS and Android",
        details: <Img src={features2} width="100%" maxHeight={200} />,
      },
      {
        title: "Games",
        text:
          "If you need a mini-game for marketing public engagement we have great ideas where we can help you to get traction to your product,",
        details: <Img src={features3} width="100%" maxHeight={200} />,
      },
      {
        title: "Computer vision and Machine learning",
        text:
          "Software Development is quickly evolving and we have now more tools than ever. Let a camera be your eyes and a computer your brain, use your data to improve your bussiness",
        details: <Img src={features4} width="100%" maxHeight={200} />,
      },
    ],
  },
  screenshots: {
    title: "Screenshots",
    screenshots: [
      {
        ImgProps: {
          src: Screenshot,
          srcSet: `${Screenshot} 1x, ${Screenshot2x} 2x`,
          alt: "Weddy Screenshot",
        },
        title: "Weddy Screenshot",
        text: "Weddy is an app for managing your wedding guests and pictures.",
      },
      {
        ImgProps: {
          src: Screenshot2,
          srcSet: `${Screenshot2} 1x, ${Screenshot22x} 2x`,
          alt: "Weddy Screenshot (2)",
        },
        title: "Weddy Screenshot (2)",
        text: "You can control all the details of your wedding from your hand.",
      },
      {
        ImgProps: {
          src: Screenshot3,
          srcSet: `${Screenshot3} 1x, ${Screenshot32x} 2x`,
          alt: "Weddy Screenshot (3)",
        },
        title: "Weddy Screenshot (3)",
        text:
          "All the guests have their profiles and can RSVP straight from the APP.",
      },
      {
        ImgProps: {
          src: Screenshot4,
          srcSet: `${Screenshot4} 1x, ${Screenshot42x} 2x`,
          alt: "Emotion recognition",
        },
        title: "In-car emotion reconition (PoC)",
        text:
          "Proof of concept of emotion recognition while driving executed for a tech talk in Tokyo. Used Mario Kart for the demo for making it more playful.",
      },
      {
        ImgProps: {
          src: Screenshot5,
          srcSet: `${Screenshot5} 1x, ${Screenshot52x} 2x`,
          alt: "Connected Car Demo",
        },
        title: "Connected Car",
        text:
          "This demo car is connected to real connected car APIs, providing functionality to change real car data like tire pressures. Connecting this app to the cloud API you can show the clients how the services provided work without using a real car.",
      },
    ],
  },
  clients: {
    title: "See what other people say about us",
    avatar: {
      src: Avatar,
      srcSet: `${Avatar} 1x, ${Avatar2x} 2x`,
      alt: "Avatar",
    },
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla mi sit amet malesuada euismod. Morbi pulvinar mauris et erat hendrerit blandit. Duis euismod lectus in eros varius, sed mattis leo posuere. In dapibus semper orci et convallis. Cras posuere dui at turpis viverra fermentum.",
    author: "Courtney Pena, Los Angeles",
  },
  pricing: {
    title: "Pricing",
    prices: {
      currency: "USD",
      types: {
        a: "Monthly",
        b: "Annually",
      },
      sections: [
        {
          title: "Beginner",
          price: "Free",
          period: "Forever",
          features: [
            {
              text: "user",
              prefix: "1",
            },
            {
              text: "clients",
              prefix: "2",
            },
            {
              text: "projects",
              prefix: "5",
            },
            {
              text: "tasks",
              prefix: "No",
            },
            {
              text: "subtasks",
              prefix: "No",
            },
            {
              text: "invoicing",
              prefix: "No",
            },
            {
              text: "estimating",
              prefix: "No",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
        {
          title: "Advanced",
          price: "$25",
          period: "per month",
          features: [
            {
              text: "user",
              prefix: "1",
            },
            {
              text: "clients",
              prefix: "Unlimited",
            },
            {
              text: "projects",
              prefix: "Unlimited",
            },
            {
              text: "tasks",
              prefix: "Unlimited",
            },
            {
              text: "subtasks",
              prefix: "No",
            },
            {
              text: "invoices per month",
              prefix: "10",
            },
            {
              text: "estimates per month",
              prefix: "10",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
        {
          title: "Expert",
          price: "$99",
          period: "per month",
          features: [
            {
              text: "user",
              prefix: "25",
            },
            {
              text: "clients",
              prefix: "Unlimited",
            },
            {
              text: "projects",
              prefix: "Unlimited",
            },
            {
              text: "tasks",
              prefix: "Unlimited",
            },
            {
              text: "subtasks",
              prefix: "Unlimited",
            },
            {
              text: "invoicing",
              prefix: "Unlimited",
            },
            {
              text: "estimating",
              prefix: "Unlimited",
            },
          ],
          button: {
            text: "Try now",
            ButtonProps: {
              as: "a",
              href: "https://mypags.app",
            },
          },
        },
      ],
    },
  },
  cta: {
    title: "Do you like what you see?",
    button: {
      label: "Buy it on Themeforest",
      href:
        "https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567",
    },
  },
  socialLinks: [
    {
      icon: Linkedin,
      href: "https://www.linkedin.com/company/gorila-digital/",
      title: "Linkedin",
    },
    {
      icon: FacebookF,
      href: "https://www.facebook.com/Gorila-Digital-100539264997895/",
      title: "Facebook",
    },
    {
      icon: Instagram,
      href: "https://www.instagram.com/goriladigital/",
      title: "Instagram",
    },
    {
      icon: Twitter,
      href: "https://twitter.com/goriladigital",
      title: "Twitter",
    },
  ],
  footer: {
    section1: {
      text: `Please contact us through our social networks or email. We provide service worldwide.`,
    },
    contact: {
      title: "Contact",
      sections: [
        {
          icon: MapMarker,
          text: textToMultiline(`Malaga\n Spain`),
        },
        {
          icon: Skype,
          text: "live:.cid.c87adf2d38ea7135",
        },
        {
          icon: Envelope,
          text: "hola@goriladigital.es",
        },
      ],
    },
    section3: {
      title: "Arrange a meeting",
      text: `Go straight to the point. Check our calendar and select one of our free appointment slots.`,
      cta: {
        text: "Open the calendar",
        href:
          "https://calendar.google.com/calendar/selfsched?sstoken=UUNjdmlsRGlwRndffGRlZmF1bHR8MDQ2N2I1OTVjZjI0ODQ3NWFhMTI5YzNiMGYwMTcwN2Y",
      },
    },
  },
  copyright: "© 2020 Gorila Digital",
};

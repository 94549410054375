import React from "react";
import styled from "styled-components";

import { base } from "@pagerland/common/src/utils";

import logo from "../../assets/Logo.svg";

const Img = styled.img`
  display: block;
  ${base};
`;

const Logo = (props) => <Img src={logo} alt="Gorila Digital logo" {...props} />;

export default Logo;
